import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('SelectDisableableTable', _vm._g(_vm._b({
    directives: [{
      name: "resize-observer",
      rawName: "v-resize-observer",
      value: _vm.onUpdate,
      expression: "onUpdate"
    }],
    ref: "table",
    staticClass: "outlined-table row-pointer",
    class: {
      canLeftScroll: _vm.canLeftScroll,
      canRightScroll: _vm.canRightScroll,
      fixedFirstColumn: _vm.fixedFirstColumn,
      fixedLastColumn: _vm.fixedLastColumn
    },
    attrs: {
      "value": _vm.value
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function fn(slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'SelectDisableableTable', _vm.attributes, false), _objectSpread(_objectSpread({}, _vm.$listeners), {}, {
    'click:row': function clickRow(item, row, event) {
      if (_vm.selectable) row.select(!row.isSelected);else _vm.$emit('click:row', item, row, event);
    },
    'update:page': function updatePage($event) {
      _vm.$el.getBoundingClientRect().top < 0 && _vm.$el.scrollIntoView({
        behavior: 'smooth'
      });
      _vm.$emit('update:page', $event);
    }
  })));
};
var staticRenderFns = [];
export { render, staticRenderFns };