/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./OutlinedTable.vue?vue&type=template&id=5a9df9c0&scoped=true"
import script from "./OutlinedTable.vue?vue&type=script&lang=js"
export * from "./OutlinedTable.vue?vue&type=script&lang=js"
import style0 from "./OutlinedTable.vue?vue&type=style&index=0&id=5a9df9c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a9df9c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectDisableableTable: require('/code/src/components/table/SelectDisableableTable.vue').default})
