import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import SelectDisableableTable from './SelectDisableableTable.vue';
import { throttle } from 'lodash-es';
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: _objectSpread(_objectSpread({}, SelectDisableableTable.props), {}, {
    fixedFirstColumn: {
      type: Boolean,
      default: false
    },
    fixedLastColumn: {
      type: Boolean,
      default: false
    }
  }),
  data: function data() {
    return {
      canLeftScroll: false,
      canRightScroll: false
    };
  },
  computed: {
    attributes: function attributes() {
      return _objectSpread(_objectSpread({
        footerProps: {
          'items-per-page-options': [10, 25, 50, 100]
        },
        mobileBreakpoint: 0
      }, this.$attrs), this.$options.propsData);
    },
    selectable: function selectable() {
      // eslint-disable-next-line no-prototype-builtins
      return this.attributes.hasOwnProperty('showSelect');
    }
  },
  mounted: function mounted() {
    var _this$$el$querySelect;
    (_this$$el$querySelect = this.$el.querySelector('.v-data-table__wrapper')) === null || _this$$el$querySelect === void 0 ? void 0 : _this$$el$querySelect.addEventListener('scroll', this.onScroll);
  },
  onUnmounted: function onUnmounted() {
    var _this$$el$querySelect2;
    (_this$$el$querySelect2 = this.$el.querySelector('.v-data-table__wrapper')) === null || _this$$el$querySelect2 === void 0 ? void 0 : _this$$el$querySelect2.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll: throttle(function () {
      this.onUpdate();
    }, 200, {
      trailing: true
    }),
    onUpdate: function onUpdate() {
      var tableWrapper = this.$el.querySelector('.v-data-table__wrapper');
      var scrollable = tableWrapper.offsetWidth < tableWrapper.scrollWidth;
      this.canRightScroll = scrollable && !(tableWrapper.offsetWidth + tableWrapper.scrollLeft >= tableWrapper.scrollWidth);
      this.canLeftScroll = scrollable && tableWrapper.scrollLeft !== 0;
    }
  }
};